<template>
  <div>
    <div class="border-top-primary border-3 border-table-radius px-0">
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="refreshTable()"
        @reset-all-filters="refreshTable()"
      >
        <template #table>
          <b-table
            ref="refScheduleList"
            class="position-relative font-small-3"
            primary-key="id"
            empty-text="No matching records found"
            select-mode="multi"
            responsive="sm"
            sticky-header="50vh"
            small
            no-border-collapse
            no-provider-filtering
            show-empty
            :fields="visibleFields"
            :items="myProvider"
            :busy.sync="isBusy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(client_name)="data">
              <div class="d-flex flex-column">
              <span style="font-weight: 100">{{ data.item.client_name }} </span>
            </div>
            <div class="d-flex flex-column">
              <span style="font-weight: 100">{{ data.item.account }} </span>
            </div>
            </template>
            <template #cell(date_event)="data">
              <span>{{ data.item.date_event | myGlobal }}</span>
            </template>

            <template #cell(hour_event)="data">
              <span>{{ data.item.hour_event | myTime }}</span>
            </template>
            <template #cell(original_hour)="data">
              <span>{{ data.item.original_hour | myGlobalDay }}</span>
            </template>
            <template #cell(commentary)="data">
              <change-sms :SmsData="data.item.commentary" />
            </template>
            <template #cell(actions)="data">
              <feather-icon
                v-if="!isCompleted"
                icon="Edit2Icon"
                class="cursor-pointer text-warning mr-1"
                @click="editScheduleCharge(data.item)"
              />
              <feather-icon
                v-if="!isCompleted"
                icon="TrashIcon"
                class="cursor-pointer text-danger"
                @click="deleteReschedulePayment(data.item)"
              />
            </template>
          </b-table>
        </template>
      </filter-slot>
      <edit-reschedule-pending-payment-modal
        v-if="reschedulePendingPaymentModal"
        @closeModal="reschedulePendingPaymentModal = false"
        :reschedule-id="selectedScheduleId"
        @refresh="$refs.refScheduleList.refresh()"
      />
    </div>
  </div>
</template>

<script>
import fields from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/data/fields.schedule-list";
import filters from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/data/filters.schedule";
import CallsAndSmsService from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/services";
import { mapGetters } from "vuex";
import agentService from "@/views/ce-digital/sub-modules/connection/views/dashboard/dashboard.service.js"
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";
import EditReschedulePendingPaymentModal from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/EditReschedulePendingPaymentModal.vue";
export default {
  name: "ScheduleListGrid",
  props: {
    allList: {
      type: Boolean,
      Default: false,
    },
    isCompleted: {
      type: Number,
      Default: null,
    },
    dateMonth: {
      type: Number,
      default: null,
    },
    dateYear: {
      type: Number,
      default: null,
    },
  },
  components: {
    ChangeSms,
    EditReschedulePendingPaymentModal,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filters,
      isBusy: false,
      fields: fields,
      fieldsSchedule: [],
      scheduleData: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      reschedulePendingPaymentModal: false,
      selectedScheduleId: null,
      sortDesc: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    
  },
  async created() {
    this.fieldsSchedule =
      this.isCeo || this.isSupervisor || this.isChief
        ? this.fields
        : this.fields.filter((e) => e.key !== "agent_name");
    if (this.isCompleted) {
      this.fields.filter((e) => e.key === "actions").visible = false;
    }
    if(this.isCeo || this.isSupervisor || this.isChief){
      // this.filters[3].options=
      this.getAdvisors()
    }
  },
  methods: {
    editScheduleCharge(item) {
      this.selectedScheduleId = item.id;
      this.reschedulePendingPaymentModal = true;
    },
    async deleteReschedulePayment(item) {
      try {
        const result = await this.showConfirmSwal();
        if (!result.isConfirmed) return;
        await CallsAndSmsService.deleteRescheduleById({
          id: item.id,
        });
        this.$refs.refScheduleList.refresh();
        this.showSuccessSwal("Reschedule deleted successfully", null);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getAdvisors() {
      if(this.filters[3].options.length === 0){
        let data = await agentService.getAllAgentsScheduleList();
        console.log(data)
      data.map((element)=>{
        this.filters[3].options.push({
          value:element['value'],
          label:element['text']
        })
      })}
      

    },
    async myProvider(ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          contacted_by: this.allList ? null : this.currentUser.user_id,
          program_id: 3,
          module_id: this.moduleId,
          completed: this.isCompleted,
          order_by: ctx.sortBy === "" ? "date_event" : ctx.sortBy,
          order: ctx.sortDesc ? "asc" : "desc",
          per_page: ctx.perPage,
          page: ctx.currentPage,
          month: this.dateMonth,
          year: this.dateYear,
          from:this.filters[0].model,
          to:this.filters[1].model,
          recurrency:this.filters[2].model,
          advisor:this.filters[3].model,
          status: this.filters[4].model
          
        };
        const { data } = await CallsAndSmsService.getAgentSchedulePendingList(params);
        const {
          data: scheduleData,
          total,
          from,
          current_page,
          per_page,
          last_page,
          to,
        } = data;
        this.scheduleData = scheduleData;
        this.paginate.currentPage = current_page;
        this.startPage = from;
        this.paginate.perPage = per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = last_page;
        this.totalRows = total;
        this.toPage = to;
        return this.scheduleData || [];
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    updateTable() {
      this.$refs.refScheduleList.refresh();
    },
        async refreshTable() {
      this.$refs.refScheduleList.refresh();
    },
  },
};
</script>

<style scoped></style>
