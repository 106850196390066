export default [
  {
    key: "client_name",
    label: "CLIENT",
    visible: true,
  },
  {
    key: "date_event",
    label: "DATE",
    visible: true,
    sortable: true,
  },
  {
    key: "hour_event",
    label: "HOUR",
    visible: true,
  },
  {
    key: "original_hour",
    label: "ORIGINAL HOUR",
    visible: true,
  },
  {
    key: "agent_name",
    label: "AGENT",
    visible: true,
  },
  {
    key: "commentary",
    label: "COMMENTARY",
    visible: true,
  },
  {
    key:"status_name",
    label:"Status",
    visible:true
  },
  {
    key: "recurrency",
    label: "Recurrency",
    visible: true,
  },
  {
    key: "actions",
    label: "ACTIONS",
    visible: true,
  },
];
