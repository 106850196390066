import { amgApi } from "@/service/axios"

class DashboardService {
  async getAgentsProductivity(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-agents-prod",
      body
    )

    return res.data
  }

  async getDepartmentProductivity(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-dept-prod",
      body
    )

    return res.data
  }

  async getDepartmentProductivityAmount(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-dept-prod-amount",
      body
    )

    return res.data
  }

  async getWeeklyProductivity(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-week-prod",
      body
    )

    return res.data
  }

  async getMonthlyProductivity(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-month-prod",
      body
    )

    return res.data
  }

  async getWorkingHours(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-work-hours",
      body
    )

    return res.data
  }

  async getAgentCommissions(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-agent-commission",
      body
    )
    return res.data
  }

  async getAllAgents(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-get-agents",
      body
    )
    return res.data
  }
  async getAllAgentsScheduleList(body) {
    const res = await amgApi.post(
      "/credit-expert/connection/dashboard-get-agents-schedule-list",
      body
    )
    return res.data
  }
}
export default new DashboardService()
