<template>
  <div>
    <b-tabs
        lazy
        pills
        nav-class="mb-0 mt-2"
        active-nav-item-class="bg-primary box-shadow-info"
    >
      <b-tab lazy title="Pending" :title-link-class="[bgTabsNavs, 'px-3']">
        <schedule-list-grid :date-month="dateMonth" :date-year="dateYear" :all-list="allList" />
      </b-tab>
      <b-tab lazy title="Completed" :title-link-class="[bgTabsNavs, 'px-3']">
        <schedule-list-grid :date-month="dateMonth" :date-year="dateYear" :is-completed="1" :all-list="allList" />
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>

import ScheduleListGrid from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/ScheduleListGrid.vue";
export default {
  name: "ScheduleList",
  components: { ScheduleListGrid },
  props: {
    allList: {
      type: Boolean,
      default: false,
    },
    dateMonth: {
      type: Number,
      default: null,
    },
    dateYear: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tabIndex: null,
    }
  },
}
</script>

<style scoped>

</style>