export default [
    {
      type: 'datepicker',
      margin: true,
      showLabel: true,
      label: 'From',
      placeholder: 'Date',
      class: 'font-small-3',
      model: null,
      locale: 'en',
      dateFormatOptions: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      cols: 6,
    },
    {
      type: 'datepicker',
      margin: true,
      showLabel: true,
      label: 'To',
      placeholder: 'Date',
      class: 'font-small-3',
      model: null,
      locale: 'en',
      dateFormatOptions: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      cols: 6,
    },
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'Recurrency',
      options: [
        { value: 1, label: 'Disabled' },
        { value: 2, label: 'Daily' },
        { value: 3, label: 'Weekly' },
        { value: 4, label: 'Monthly' },
        { value: 5, label: 'Yearly' },
      ],
      model: '',
      reduce: 'value',
      selectText: 'label',
      cols: 12,
      visible: true,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
      label: 'Agent',
        options: [

        ],
        model: '',
        reduce: 'value',
        selectText: 'label',
        cols: 12,
        visible: true,
      },
      {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Status',
        options: [
          { value: 1, label: 'PENDING' },
          { value: 2, label: 'IN PROCESS' },
          { value: 3, label: 'CONTACTED' },
          { value: 4, label: 'NOT CONTACTED' },
  
        ],
        model: '',
        reduce: 'value',
        selectText: 'label',
        cols: 12,
        visible: true,
      },
  ]
  